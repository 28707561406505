<template>
  <tr>
    <td class="text-center">{{ item.id }}</td>
    <td class="text-center">{{ item.name }}</td>
    <td class="text-right"></td>
  </tr>
</template>
<script>
  export default {
    name: 'RoleItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
  }
</script>
